import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react"
import { Link, navigate } from "gatsby"
import { any } from "prop-types"
import React, { Component, useEffect, useState } from "react"
import { QueryClient, QueryClientProvider, useMutation } from "react-query"
import { toast } from "react-toastify"
import { StringParam, useQueryParam } from "use-query-params"
import LoadingModal from "../components/Modal/LoadingModal"
import { MyDonorPaymentReceipt } from "../components/MyPage/MyDonorPaymentReceipt"
import { loginRequest } from "../components/Security/authConfig"
import { ErrorComponent } from "../components/Utils/ErrorComponent"
import { patchApi } from "../components/Utils/WebApi-utils"

const queryClient = new QueryClient()

const ReceiptPageContent = () => {
  return (
    <div>
      <div className="px-8 lg:px-32">
        <div className=" border-b-2 border-blue pt-2 mb-0">
          <Link
            to="/"
            className="flex flex-row items-center font-sans text-blue text-sm mb-2 hover:text-orange-dark hover:cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
            Min side
          </Link>
        </div>
      </div>
      <MyDonorPaymentReceipt />
    </div>
  )
}

const ReceiptPage = () => {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      //   loadingComponent={LoadingModal}
    >
      <QueryClientProvider client={queryClient}>
        <ReceiptPageContent />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default ReceiptPage
